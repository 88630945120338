import React, { useEffect, useRef, useState } from "react";

import Spacing from "../Spacing";
import SectionHeading from "../SectionHeading";
import dear from "../../images/logo/Group.png";
import reat from "../../images/logo/React-Icon.png";
import js from "../../images/logo/logo-javascript.png";
import html from "../../images/logo/html-1.png";
import cosmosdb from "../../images/logo/CosmosDb.png";
import azure from "../../images/logo/Microsoft_Azure.png";
import imgArrowRight from "../../images/icons/rightArrow.png";
import AOS from "aos";
import CareersPage from "./CareersPage";
import ContactPage from "./ContactPage";
import LandingPage from "../LandingPage/LandingPage";
import vision from "../../images/banner/Vision.png";
import Team from "../../images/banner/Team.png";
import roundBg from "../../images/banner/roundBg.png";
import { useLocation, useOutletContext } from "react-router-dom";

export default function Home() {
  const careerRef = useRef(null);
  const { setCareerRef } = useOutletContext();
 

  useEffect(() => {
    if (setCareerRef) {
      setCareerRef(careerRef);
    }
  }, [setCareerRef]);
;
  const stickyElementsRef = useRef([]);
  const technologies = [
    { image: js, text: "JavaScript" },
    { image: reat, text: "React Native" },
    { image: reat, text: "React JS" },
    { image: html, text: "HTML5" },
    { image: azure, text: "Azure" },
    { image: cosmosdb, text: "CosmosDB" },
  ];
  const [isHovered, setIsHovered] = useState(null);

  function hoverOnHandler(index) {
    setIsHovered(index);
  }
  function hoverOffHandler() {
    setIsHovered(null);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      stickyElementsRef.current.forEach((element) => {
        const rect = element.getBoundingClientRect();
        if (rect.top <= window.innerHeight && rect.bottom >= 0) {
          element.classList.add("revealed");
        } else {
          element.classList.remove("revealed");
        }
      });
      AOS.init();
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div id="landing" className="glassBg">
      <LandingPage />
      <Spacing lg="60" md="30" />
      <Spacing lg="60" md="30" />
      <div className="containerBg">
        <div className="container">
          <div className="pt-5 topTitle ballLightVison">
            <h3>Our Vision</h3>
            <div className="sectionTwoGrid">
              <div
                className="m-auto"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <img src={vision} alt="vision" />
              </div>
              <div className="m-auto">
                <p className="mb-3">
                  Our vision is to lead the way in innovation, creating
                  cutting-edge solutions today that shape a brighter,
                  sustainable tomorrow.
                </p>
                <ul>
                  <li>
                    <strong>Innovative Gaming :</strong> We aim to redefine
                    mobile gaming with engaging and impactful experiences that
                    captivate players and push the boundaries of what’s
                    possible.
                  </li>
                  <li>
                    <strong>Cutting-Edge Technology :</strong> Utilizing
                    creative designs and developing engaging games using AI,
                    data science, and machine learning, we deliver gaming
                    experiences that exceed player expectations and set new
                    industry standards.
                  </li>
                  <li>
                    <strong>Player-Centric Design :</strong> Our focus is on
                    creating seamless, immersive, and unforgettable gaming
                    experiences that prioritize player satisfaction and
                    engagement.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <Spacing lg="100" md="50" />
          <div className="topTitle ballLightTeam">
            <h3>Our Unique Team</h3>
            <div className="sectionTwoGrid">
              <div className="m-auto">
                <p className="mb-3">
                  Our team is the heart and soul of Charles Technologies.
                  Comprising talented developers, designers, and strategists, we
                  bring a diverse set of skills and perspectives to the table.
                </p>
                <ul>
                  <li>
                    <strong>Collaborative Innovation :</strong> Our
                    collaborative approach and passion for innovation set us
                    apart, enabling us to create unique solutions.
                  </li>
                  <li>
                    <strong>Commitment to Continuous Learning :</strong> We
                    believe in continuous learning and growth, ensuring that our
                    team stays ahead of industry trends.
                  </li>
                  <li>
                    <strong>Dedication to Excellence :</strong> Our team members
                    are dedicated to creating applications that make a
                    difference, fostering a work environment that encourages
                    creativity, collaboration, and a relentless pursuit of
                    excellence.
                  </li>
                </ul>
              </div>
              <div
                className="m-auto"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <img src={Team} alt="Team" />
              </div>
            </div>
          </div>

          <Spacing lg="60" md="60" />
          <div className="pb-5">
            Our passion lies in creating immersive, engaging, and impactful
            gaming experiences that captivate players and keep them coming back
            for more. At Charles Technologies, we push the boundaries of what’s
            possible in mobile gaming, ensuring each game we develop offers a
            unique and memorable journey.
          </div>
        </div>
      </div>

      <div className="container-fluid portfolioBg" id="home">
        <div className="container">
          <div
            className="sectionTwoGrid mobileSectionCenter  align-items-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div
              className="m-auto  portfolioImgBg"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="roundBg">
                <img src={roundBg} alt="roundBg" />
              </div>
              <img src={dear} alt="Portfolio" />
            </div>

            <div className=" portfolioLeft">
              <h3>Our Portfolio</h3>
              <Spacing lg="40" md="40" />
              <h6>Dear Government Lottery</h6>
              <Spacing lg="20" md="20" />
              <p className="mobilePadd">
                A secure and user-friendly platform for purchasing official
                government lottery tickets online. Our app ensures seamless
                transactions with robust encryption, protecting user data and
                payment information. Join us for a convenient and trusted
                lottery experience, with instant notifications and real-time
                updates on your tickets and results. Dear Government Lottery was
                the first lottery company in India to facilitate the live
                telecast of draws on TV, conducted by various governments for
                lotteries distributed through them. This innovation brought more
                trust, credibility, and transparency to the trade, significantly
                enhancing the company's reputation.
              </p>
              <a href="https://dearlotteries.app/" target="_blank">
                Read More <img src={imgArrowRight} alt="Arrow" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <Spacing lg="60" md="60" />
      <div className="container" data-aos="fade-up" data-aos-duration="1000">
        <h2 className="text-center techTitle">Technologies</h2>
        <SectionHeading variant="cs-style1 text-center" />
        <Spacing lg="60" md="30" />

        <div className="imageGrid ballLightTech" id="careers" ref={careerRef}>
          {technologies.map((technology, index) => (
            <div
              key={index}
              className={`text-center ${
                isHovered === index ? "techIconOne" : "techIcon"
              }`}
              onMouseEnter={() => hoverOnHandler(index)}
              onMouseLeave={hoverOffHandler}
              onClick={() => hoverOnHandler(index)}
            >
              <img
                src={technology.image}
                className="box-img"
                alt={technology.text}
              />
              <h5
                className={`text-center ${
                  isHovered === index ? "techtextOne" : "techtext"
                }`}
              >
                {technology.text}
              </h5>
            </div>
          ))}
        </div>
      </div>
      <Spacing lg="60" md="30" />
      <CareersPage />
      <ContactPage />
    </div>
  );
}
